import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Platform,
  ScrollView,
} from "react-native";
import { Button, Text, Card, IconButton } from "react-native-paper";
import React, { useState, useEffect } from "react";
import * as Haptics from "expo-haptics";
import { Accelerometer } from "expo-sensors";
import domtoimage from "dom-to-image";
import { Audio } from "expo-av";

const rollAD6 = () => {
  return Math.floor(Math.random() * 6) + 1;
};
const rollAD20 = () => {
  return Math.floor(Math.random() * 20) + 1;
};
const rollAD100 = () => {
  return Math.floor(Math.random() * 100) + 1;
};

const rollAD10 = () => {
  return Math.floor(Math.random() * 10) + 1;
};

const rollAD4 = () => {
  return Math.floor(Math.random() * 4) + 1;
};

const rollAD8 = () => {
  return Math.floor(Math.random() * 8) + 1;
};

const rollAD12 = () => {
  return Math.floor(Math.random() * 12) + 1;
};

export default function QuickRole({ navigation }) {
  const [total, setTotal] = useState(0);
  const [dice, setDice] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [totals, setTotals] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [sound, setSound] = useState();

  function wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }

  async function playDiceSound() {
    const { sound } = await Audio.Sound.createAsync(
      require("../../assets/sounds/rollingdice.mp4")
    );
    setSound(sound);
    await sound.playAsync();
  }

  const shakePhone = async () => {
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
    wait(100);
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
    wait(100);
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Warning);
    wait(100);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    wait(100);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
    wait(100);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
    wait(100);
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
    wait(100);
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
    wait(100);
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Warning);
    wait(100);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    wait(100);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
    wait(100);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
    wait(100);
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
    wait(100);
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
    wait(100);
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Warning);
    wait(100);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    wait(100);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
    wait(100);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
    wait(200);
    return;
  };

  const shaker = async () => {
    if (Platform.OS !== "web") {
      await playDiceSound();
      shakePhone();
      return;
    } else if (Platform.OS === "web") {
      await playDiceSound();
      wait(2000);
      return;
    }
  };

  return (
    <View style={styles.container}>
      <ScrollView
        style={{ width: "100%" }}
        contentContainerStyle={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card style={styles.quickCard}>
          <Card.Title title="Roll a D4" />
          <Card.Content>
            <Text>Number of D4: {dice[0]}</Text>
            <Text>D4 Roll Total: {totals[0]}</Text>
          </Card.Content>
          <Card.Actions>
            <IconButton
              mode="contained"
              icon="dice-d4-outline"
              onPress={() => {
                const newDice = [...dice];
                let newTotals = [...totals];
                newTotals[0] = 0;
                for (let i = 0; i < newDice[0]; i++) {
                  newTotals[0] = newTotals[0] + rollAD4();
                }
                setTotals(newTotals);
              }}
            />
            <IconButton
              mode="contained"
              icon="plus"
              onPress={() => {
                const newDice = [...dice];
                newDice[0] = newDice[0] + 1;
                setDice(newDice);
              }}
            />
            <IconButton
              mode="contained"
              icon="minus"
              onPress={() => {
                const newDice = [...dice];
                if (newDice[0] > 0) {
                  newDice[0] = newDice[0] - 1;
                  setDice(newDice);
                }
              }}
            />
          </Card.Actions>
        </Card>
        <Card style={styles.quickCard}>
          <Card.Title title="Roll a D6" />
          <Card.Content>
            <Text>Number of D6: {dice[1]}</Text>
            <Text>D6 Roll Total: {totals[1]}</Text>
          </Card.Content>
          <Card.Actions>
            <IconButton
              mode="contained"
              icon="dice-d6-outline"
              onPress={() => {
                const newDice = [...dice];
                let newTotals = [...totals];
                newTotals[1] = 0;
                for (let i = 0; i < newDice[1]; i++) {
                  newTotals[1] = newTotals[1] + rollAD6();
                }
                setTotals(newTotals);
              }}
            />
            <IconButton
              mode="contained"
              icon="plus"
              onPress={() => {
                const newDice = [...dice];
                newDice[1] = newDice[1] + 1;
                setDice(newDice);
              }}
            />
            <IconButton
              mode="contained"
              icon="minus"
              onPress={() => {
                const newDice = [...dice];
                if (newDice[1] > 0) {
                  newDice[1] = newDice[1] - 1;
                  setDice(newDice);
                }
              }}
            />
          </Card.Actions>
        </Card>
        <Card style={styles.quickCard}>
          <Card.Title title="Roll a D8" />
          <Card.Content>
            <Text>Number of D8: {dice[2]}</Text>
            <Text>D8 Roll Total: {totals[2]}</Text>
          </Card.Content>
          <Card.Actions>
            <IconButton
              mode="contained"
              icon="dice-d8-outline"
              onPress={() => {
                const newDice = [...dice];
                let newTotals = [...totals];
                newTotals[2] = 0;
                for (let i = 0; i < newDice[2]; i++) {
                  newTotals[2] = newTotals[2] + rollAD8();
                }
                setTotals(newTotals);
              }}
            />
            <IconButton
              mode="contained"
              icon="plus"
              onPress={() => {
                const newDice = [...dice];
                newDice[2] = newDice[2] + 1;
                setDice(newDice);
              }}
            />
            <IconButton
              mode="contained"
              icon="minus"
              onPress={() => {
                const newDice = [...dice];
                if (newDice[2] > 0) {
                  newDice[2] = newDice[2] - 1;
                  setDice(newDice);
                }
              }}
            />
          </Card.Actions>
        </Card>
        <Card style={styles.quickCard}>
          <Card.Title title="Roll a D10" />
          <Card.Content>
            <Text>Number of D10: {dice[3]}</Text>
            <Text>D10 Roll Total: {totals[3]}</Text>
          </Card.Content>
          <Card.Actions>
            <IconButton
              mode="contained"
              icon="dice-d10-outline"
              onPress={() => {
                const newDice = [...dice];
                let newTotals = [...totals];
                newTotals[3] = 0;
                for (let i = 0; i < newDice[3]; i++) {
                  newTotals[3] = newTotals[3] + rollAD10();
                }
                setTotals(newTotals);
              }}
            />
            <IconButton
              mode="contained"
              icon="plus"
              onPress={() => {
                const newDice = [...dice];
                newDice[3] = newDice[3] + 1;
                setDice(newDice);
              }}
            />
            <IconButton
              mode="contained"
              icon="minus"
              onPress={() => {
                const newDice = [...dice];
                if (newDice[3] > 0) {
                  newDice[3] = newDice[3] - 1;
                  setDice(newDice);
                }
              }}
            />
          </Card.Actions>
        </Card>
        <Card style={styles.quickCard}>
          <Card.Title title="Roll a D12" />
          <Card.Content>
            <Text>Number of D12: {dice[4]}</Text>
            <Text>D12 Roll Total: {totals[4]}</Text>
          </Card.Content>
          <Card.Actions>
            <IconButton
              mode="contained"
              icon="dice-d12-outline"
              onPress={() => {
                const newDice = [...dice];
                let newTotals = [...totals];
                newTotals[4] = 0;
                for (let i = 0; i < newDice[4]; i++) {
                  newTotals[4] = newTotals[4] + rollAD12();
                }
                setTotals(newTotals);
              }}
            />
            <IconButton
              mode="contained"
              icon="plus"
              onPress={() => {
                const newDice = [...dice];
                newDice[4] = newDice[4] + 1;
                setDice(newDice);
              }}
            />
            <IconButton
              mode="contained"
              icon="minus"
              onPress={() => {
                const newDice = [...dice];
                if (newDice[4] > 0) {
                  newDice[4] = newDice[4] - 1;
                  setDice(newDice);
                }
              }}
            />
          </Card.Actions>
        </Card>
        <Card style={styles.quickCard}>
          <Card.Title title="Roll a D20" />
          <Card.Content>
            <Text>Number of D20: {dice[5]}</Text>
            <Text>D20 Roll Total: {totals[5]}</Text>
          </Card.Content>
          <Card.Actions>
            <IconButton
              mode="contained"
              icon="dice-d20-outline"
              onPress={() => {
                const newDice = [...dice];
                let newTotals = [...totals];
                newTotals[5] = 0;
                for (let i = 0; i < newDice[5]; i++) {
                  newTotals[5] = newTotals[5] + rollAD20();
                }
                setTotals(newTotals);
              }}
            />
            <IconButton
              mode="contained"
              icon="plus"
              onPress={() => {
                const newDice = [...dice];
                newDice[5] = newDice[5] + 1;
                setDice(newDice);
              }}
            />
            <IconButton
              mode="contained"
              icon="minus"
              onPress={() => {
                const newDice = [...dice];
                if (newDice[5] > 0) {
                  newDice[5] = newDice[5] - 1;
                  setDice(newDice);
                }
              }}
            />
          </Card.Actions>
        </Card>
        <Card style={styles.quickCard}>
          <Card.Title title="Roll a D100" />
          <Card.Content>
            <Text>Number of D100: {dice[6]}</Text>
            <Text>D100 Roll Total: {totals[6]}</Text>
          </Card.Content>
          <Card.Actions>
            <IconButton
              mode="contained"
              icon="dice-multiple-outline"
              onPress={() => {
                const newDice = [...dice];
                let newTotals = [...totals];
                newTotals[6] = 0;
                for (let i = 0; i < newDice[6]; i++) {
                  newTotals[6] = newTotals[6] + rollAD100();
                }
                setTotals(newTotals);
              }}
            />
            <IconButton
              mode="contained"
              icon="plus"
              onPress={() => {
                const newDice = [...dice];
                newDice[6] = newDice[6] + 1;
                setDice(newDice);
              }}
            />
            <IconButton
              mode="contained"
              icon="minus"
              onPress={() => {
                const newDice = [...dice];
                if (newDice[6] > 0) {
                  newDice[6] = newDice[6] - 1;
                  setDice(newDice);
                }
              }}
            />
          </Card.Actions>
        </Card>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            marginBottom: 200,
          }}
        >
          <View style={{ margin: 20 }}>
            <Button
              mode="contained"
              onPress={() => {
                setTotal(0);
                setDice([0, 0, 0, 0, 0, 0]);
                setTotals([0, 0, 0, 0, 0, 0, 0]);
              }}
            >
              Reset All
            </Button>
          </View>
          <Button
            style={{ margin: 20 }}
            mode="contained"
            onPress={() => {
              navigation.navigate("Dashboard");
            }}
          >
            Dashboard
          </Button>
          <Button
            style={{ margin: 20 }}
            mode="contained"
            onPress={() => {
              navigation.navigate("Profile Manager");
            }}
          >
            Profile Manager
          </Button>
        </View>
        <StatusBar style="auto" />
      </ScrollView>
      <Card
        style={{ position: "absolute", bottom: 10, backgroundColor: "#3b2b3b" }}
      >
        <Card.Content>
          <View style={{ flex: 1, flexDirection: "row" }}>
            <View style={{ margin: 20 }}>
              <Text>Total Rolled: {total}</Text>
            </View>
            <View style={{ margin: 20 }}>
              <Button
                mode="contained"
                onPress={async () => {
                  await shaker();
                  const newDice = [...dice];
                  let newTotals = [0, 0, 0, 0, 0, 0, 0];
                  for (let i = 0; i < newDice[0]; i++) {
                    newTotals[0] = newTotals[0] + rollAD4();
                  }
                  for (let i = 0; i < newDice[1]; i++) {
                    newTotals[1] = newTotals[1] + rollAD6();
                  }
                  for (let i = 0; i < newDice[2]; i++) {
                    newTotals[2] = newTotals[2] + rollAD8();
                  }
                  for (let i = 0; i < newDice[3]; i++) {
                    newTotals[3] = newTotals[3] + rollAD10();
                  }
                  for (let i = 0; i < newDice[4]; i++) {
                    newTotals[4] = newTotals[4] + rollAD12();
                  }
                  for (let i = 0; i < newDice[5]; i++) {
                    newTotals[5] = newTotals[5] + rollAD20();
                  }
                  for (let i = 0; i < newDice[6]; i++) {
                    newTotals[6] = newTotals[6] + rollAD100();
                  }
                  setTotals(newTotals);
                  setTotal(newTotals.reduce((a, b) => a + b, 0));
                }}
              >
                Roll All
              </Button>
            </View>
          </View>
        </Card.Content>
      </Card>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#27394A",
  },
  button: {
    margin: 20,
    padding: 20,
  },
  text: {
    textAlign: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "stretch",
    marginTop: 15,
  },
  middleButton: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  navButton: {
    margin: 20,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    minWidth: 300,
    backgroundColor: "#262120",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalText: {
    marginBottom: 15,
    fontSize: 20,
    color: "white",
    textAlign: "center",
  },
  quickCard: {
    margin: 20,
    maxWidth: 800,
    minWidth: 300,
  },
});
