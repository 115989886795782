import "react-native-gesture-handler";
import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createDrawerNavigator } from "@react-navigation/drawer";
import Dashboard from "./src/pages/Dashboard";
import ProfileManager from "./src/pages/ProfileManager";
import QuickRole from "./src/pages/QuickRole";
import {
  PaperProvider,
  MD3DarkTheme as DefaultTheme,
} from "react-native-paper";
import AddAProfile from "./src/pages/AddAProfile";

const Drawer = createDrawerNavigator();
const theme = {
  ...DefaultTheme,
};

function MyDrawer() {
  return (
    <PaperProvider theme={theme}>
      <NavigationContainer>
        <Drawer.Navigator
          screenOptions={{
            drawerStyle: {
              backgroundColor: "#262120",
              width: 240,
            },
            drawerActiveBackgroundColor: "#3f3a36",
            drawerActiveTintColor: "#ffffff",
            drawerInactiveTintColor: "#ffffff",
            headerTintColor: "#ffffff",
            headerStyle: {
              backgroundColor: "#262120",
              borderColor: "#262120",
            },
          }}
        >
          <Drawer.Screen name="Dashboard" component={Dashboard} />
          <Drawer.Screen name="Profile Manager" component={ProfileManager} />
          <Drawer.Screen name="Quick Roll" component={QuickRole} />
          <Drawer.Screen name="Add a Profile" component={AddAProfile} />
        </Drawer.Navigator>
      </NavigationContainer>
    </PaperProvider>
  );
}
export default MyDrawer;
