import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Platform,
  ScrollView,
  Modal,
} from "react-native";
import {
  Button,
  Text,
  Card,
  Portal,
  TextInput,
  IconButton,
} from "react-native-paper";
import React, { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

const rollAD6 = () => {
  return Math.floor(Math.random() * 6) + 1;
};
const rollAD20 = () => {
  return Math.floor(Math.random() * 20) + 1;
};
const rollAD100 = () => {
  return Math.floor(Math.random() * 100) + 1;
};

const rollAD10 = () => {
  return Math.floor(Math.random() * 10) + 1;
};

const rollAD4 = () => {
  return Math.floor(Math.random() * 4) + 1;
};

const rollAD8 = () => {
  return Math.floor(Math.random() * 8) + 1;
};

const rollAD12 = () => {
  return Math.floor(Math.random() * 12) + 1;
};

export default function AddAProfile({ navigation }) {
  const [total, setTotal] = useState(0);
  const [dice, setDice] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [totals, setTotals] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [sound, setSound] = useState();
  const [profiles, setProfiles] = useState([]);
  const [numberOfD4, setNumberOfD4] = useState("0");
  const [numberOfD6, setNumberOfD6] = useState("0");
  const [numberOfD8, setNumberOfD8] = useState("0");
  const [numberOfD10, setNumberOfD10] = useState("0");
  const [numberOfD12, setNumberOfD12] = useState("0");
  const [numberOfD20, setNumberOfD20] = useState("0");
  const [numberOfD100, setNumberOfD100] = useState("0");
  const [profileName, setProfileName] = useState("");
  const [successAddingProfile, setSuccessAddingProfile] = useState(false);
  const [areAdding, setAreAdding] = useState(false);

  const getProfiles = async () => {
    const tempProfiles = JSON.parse(await AsyncStorage.getItem("Profiles"));
    if (tempProfiles !== null) {
      setProfiles(tempProfiles);
    }
  };
  const addProfiles = async () => {
    try {
      const tempProfiles = JSON.parse(await AsyncStorage.getItem("Profiles"));
      if (tempProfiles !== null) {
        const binProfiles = [
          ...tempProfiles,
          {
            name: profileName,
            dice: [
              numberOfD4,
              numberOfD6,
              numberOfD8,
              numberOfD10,
              numberOfD12,
              numberOfD20,
              numberOfD100,
            ],
          },
        ];
        await AsyncStorage.setItem("Profiles", JSON.stringify(binProfiles));
      } else {
        await AsyncStorage.setItem(
          "Profiles",
          JSON.stringify([
            {
              name: profileName,
              dice: [
                numberOfD4,
                numberOfD6,
                numberOfD8,
                numberOfD10,
                numberOfD12,
                numberOfD20,
                numberOfD100,
              ],
            },
          ])
        );
      }
    } catch (e) {
      console.log(e);
    }
    setProfileName("");
    setNumberOfD4(0);
    setNumberOfD6(0);
    setNumberOfD8(0);
    setNumberOfD10(0);
    setNumberOfD12(0);
    setNumberOfD20(0);
    setNumberOfD100(0);
  };

  return (
    <View style={styles.container}>
      <ScrollView
        style={{ width: "100%", marginTop: 10 }}
        contentContainerStyle={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text>Profile Name:</Text>
        <TextInput
          style={styles.inputStyle}
          dense
          value={profileName}
          onChangeText={(text) => {
            setProfileName(text);
          }}
        />
        <Text>Number of D4 Dice:</Text>
        <TextInput
          keyboardType="numeric"
          style={styles.inputStyle}
          dense
          value={numberOfD4}
          onChangeText={(text) => {
            setNumberOfD4(text);
          }}
        />
        <Text>Number of D6 Dice:</Text>
        <TextInput
          keyboardType="numeric"
          style={styles.inputStyle}
          dense
          value={numberOfD6}
          onChangeText={(text) => {
            setNumberOfD6(text);
          }}
        />
        <Text>Number of D8 Dice:</Text>
        <TextInput
          keyboardType="numeric"
          style={styles.inputStyle}
          dense
          value={numberOfD8}
          onChangeText={(text) => {
            setNumberOfD8(text);
          }}
        />
        <Text>Number of D10 Dice:</Text>
        <TextInput
          keyboardType="numeric"
          style={styles.inputStyle}
          dense
          value={numberOfD10}
          onChangeText={(text) => {
            setNumberOfD10(text);
          }}
        />
        <Text>Number of D12 Dice:</Text>
        <TextInput
          keyboardType="numeric"
          style={styles.inputStyle}
          dense
          value={numberOfD12}
          onChangeText={(text) => {
            setNumberOfD12(text);
          }}
        />
        <Text>Number of D20 Dice:</Text>
        <TextInput
          keyboardType="numeric"
          style={styles.inputStyle}
          dense
          value={numberOfD20}
          onChangeText={(text) => {
            setNumberOfD20(text);
          }}
        />
        <Text>Number of D100 Dice:</Text>
        <TextInput
          keyboardType="numeric"
          style={styles.inputStyle}
          dense
          value={numberOfD100}
          onChangeText={(text) => {
            setNumberOfD100(text);
          }}
        />
        <View style={{ flex: 1, flexDirection: "row" }}>
          <Button
            style={{ margin: 10 }}
            mode="contained"
            onPress={async () => {
              await addProfiles();
              setSuccessAddingProfile(true);
              setAreAdding(false);
              getProfiles();
            }}
          >
            Add
          </Button>
        </View>
        <Button
          style={{ margin: 10 }}
          mode="contained"
          onPress={() => {
            navigation.navigate("Dashboard");
          }}
        >
          Dashboard
        </Button>
        <Button
          style={{ margin: 10 }}
          mode="contained"
          onPress={() => {
            navigation.navigate("Profile Manager");
          }}
        >
          Profile Manager
        </Button>
        <StatusBar style="auto" />
      </ScrollView>
      <Modal
        visible={successAddingProfile}
        animationType="slide"
        transparent={true}
        onDismiss={() => {
          setSuccessAddingProfile(false);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text>Profile Added!</Text>
            <View style={{ margin: 10 }}>
              <Button
                mode="contained"
                title="Close"
                onPress={() => {
                  setSuccessAddingProfile(false);
                }}
              >
                <Text>Close</Text>
              </Button>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#27394A",
  },
  button: {
    margin: 20,
    padding: 20,
  },
  text: {
    textAlign: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "stretch",
    marginTop: 15,
  },
  middleButton: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  navButton: {
    margin: 20,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    minWidth: 300,
    backgroundColor: "#262120",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalText: {
    marginBottom: 15,
    fontSize: 20,
    color: "white",
    textAlign: "center",
  },
  quickCard: {
    margin: 20,
    maxWidth: 800,
    minWidth: 300,
  },
  inputStyle: {
    minWidth: 300,
  },
});
