import React from "react";
import { StatusBar } from "expo-status-bar";
import { View, ScrollView, StyleSheet, Modal } from "react-native";
import {
  Button,
  Text,
  Card,
  Portal,
  TextInput,
  IconButton,
} from "react-native-paper";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useState, useEffect } from "react";

export default function ProfileManager({ navigation }) {
  const [profiles, setProfiles] = useState([]);
  const [sureDelete, setSureDelete] = useState(false);
  const [profileToDelete, setProfileToDelete] = useState("");
  const [profileToEdit, setProfileToEdit] = useState("");
  const [areEditing, setAreEditing] = useState(false);
  const [numberOfD4, setNumberOfD4] = useState("0");
  const [numberOfD6, setNumberOfD6] = useState("0");
  const [numberOfD8, setNumberOfD8] = useState("0");
  const [numberOfD10, setNumberOfD10] = useState("0");
  const [numberOfD12, setNumberOfD12] = useState("0");
  const [numberOfD20, setNumberOfD20] = useState("0");
  const [numberOfD100, setNumberOfD100] = useState("0");
  const [profileName, setProfileName] = useState("");
  const [successEditingProfile, setSuccessEditingProfile] = useState(false);
  const [successAddingProfile, setSuccessAddingProfile] = useState(false);
  const [areAdding, setAreAdding] = useState(false);

  const deleteProfile = async (profileName) => {
    if (profiles !== null) {
      const newProfiles = profiles.filter(
        (profile) => profile.name !== profileName
      );
      await AsyncStorage.setItem("Profiles", JSON.stringify(newProfiles));
    }
  };

  const getProfiles = async () => {
    const tempProfiles = JSON.parse(await AsyncStorage.getItem("Profiles"));
    if (tempProfiles !== null) {
      setProfiles(tempProfiles);
    }
  };
  const addProfiles = async () => {
    try {
      const tempProfiles = JSON.parse(await AsyncStorage.getItem("Profiles"));
      if (tempProfiles !== null) {
        const binProfiles = [
          ...tempProfiles,
          {
            name: profileName,
            dice: [
              numberOfD4,
              numberOfD6,
              numberOfD8,
              numberOfD10,
              numberOfD12,
              numberOfD20,
              numberOfD100,
            ],
          },
        ];
        await AsyncStorage.setItem("Profiles", JSON.stringify(binProfiles));
      } else {
        await AsyncStorage.setItem(
          "Profiles",
          JSON.stringify([
            {
              name: profileName,
              dice: [
                numberOfD4,
                numberOfD6,
                numberOfD8,
                numberOfD10,
                numberOfD12,
                numberOfD20,
                numberOfD100,
              ],
            },
          ])
        );
      }
    } catch (e) {
      console.log(e);
    }
    setProfileName("");
    setNumberOfD4(0);
    setNumberOfD6(0);
    setNumberOfD8(0);
    setNumberOfD10(0);
    setNumberOfD12(0);
    setNumberOfD20(0);
    setNumberOfD100(0);
  };

  const editProfiles = async () => {
    if (profiles !== null) {
      const newProfiles = profiles.filter(
        (profile) => profile.name !== profileToEdit
      );
      await AsyncStorage.setItem("Profiles", JSON.stringify(newProfiles));
      await AsyncStorage.setItem(
        "Profiles",
        JSON.stringify([
          ...newProfiles,
          {
            name: profileName,
            dice: [
              numberOfD4,
              numberOfD6,
              numberOfD8,
              numberOfD10,
              numberOfD12,
              numberOfD20,
              numberOfD100,
            ],
          },
        ])
      );
    }
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      getProfiles();
    });

    return unsubscribe;
  }, [navigation]);
  return (
    <View style={styles.container}>
      <ScrollView
        style={{ width: "100%" }}
        contentContainerStyle={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {profiles.map((profile) => {
          return (
            <View key={profile.name}>
              <Card style={{ minWidth: 300, margin: 20, maxWidth: 800 }}>
                <Card.Title title={profile.name} />
                <Card.Actions>
                  <IconButton
                    mode="contained"
                    icon="account-edit-outline"
                    size={24}
                    onPress={() => {
                      setProfileToEdit(profile.name);
                      setProfileName(profile.name);
                      setNumberOfD4(profile.dice[0]);
                      setNumberOfD6(profile.dice[1]);
                      setNumberOfD8(profile.dice[2]);
                      setNumberOfD10(profile.dice[3]);
                      setNumberOfD12(profile.dice[4]);
                      setNumberOfD20(profile.dice[5]);
                      setNumberOfD100(profile.dice[6]);
                      setAreEditing(true);
                    }}
                  />
                  <IconButton
                    mode="contained"
                    icon="delete-outline"
                    size={24}
                    onPress={() => {
                      setSureDelete(true);
                      setProfileToDelete(profile.name);
                    }}
                  />
                </Card.Actions>
              </Card>
            </View>
          );
        })}

        <Button
          style={{ margin: 20 }}
          mode="contained"
          onPress={() => {
            setAreAdding(true);
          }}
        >
          Add A Profile
        </Button>
        <Button
          mode="contained"
          style={{ margin: 20 }}
          title="Go to Dashboard"
          onPress={() => navigation.navigate("Dashboard")}
        >
          Go To Dashboard
        </Button>
      </ScrollView>
      <Modal
        visible={sureDelete}
        animationType="slide"
        transparent={true}
        onDismiss={() => {
          setSureDelete(false);
          setProfileToDelete("");
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text>Are you sure you want to delete {profileToDelete}?</Text>
            <View style={{ flex: 1, flexDirection: "row", margin: 10 }}>
              <View style={{ margin: 10 }}>
                <Button
                  mode="contained"
                  onPress={() => {
                    deleteProfile(profileToDelete);
                    setSureDelete(false);
                    setProfileToDelete("");
                    getProfiles();
                  }}
                >
                  Yes
                </Button>
              </View>
              <View style={{ margin: 10 }}>
                <Button
                  mode="contained"
                  onPress={() => {
                    setSureDelete(false);
                    setProfileToDelete("");
                  }}
                >
                  No
                </Button>
              </View>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        visible={areEditing}
        animationType="slide"
        transparent={true}
        onDismiss={() => {
          setAreEditing(false);
          setProfileToEdit("");
        }}
      >
        <ScrollView>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Text style={{ margin: 10 }}>Editing: {profileToEdit}</Text>
              <Text>Profile Name:</Text>
              <TextInput
                style={styles.inputStyle}
                dense
                value={profileName}
                onChangeText={(text) => {
                  setProfileName(text);
                }}
              />
              <Text>Number of D4 Dice:</Text>
              <TextInput
                style={styles.inputStyle}
                keyboardType="numeric"
                dense
                value={numberOfD4}
                onChangeText={(text) => {
                  setNumberOfD4(text);
                }}
              />
              <Text>Number of D6 Dice:</Text>
              <TextInput
                style={styles.inputStyle}
                keyboardType="numeric"
                dense
                value={numberOfD6}
                onChangeText={(text) => {
                  setNumberOfD6(text);
                }}
              />
              <Text>Number of D8 Dice:</Text>
              <TextInput
                style={styles.inputStyle}
                keyboardType="numeric"
                dense
                value={numberOfD8}
                onChangeText={(text) => {
                  setNumberOfD8(text);
                }}
              />
              <Text>Number of D10 Dice:</Text>
              <TextInput
                style={styles.inputStyle}
                keyboardType="numeric"
                dense
                value={numberOfD10}
                onChangeText={(text) => {
                  setNumberOfD10(text);
                }}
              />
              <Text>Number of D12 Dice:</Text>
              <TextInput
                style={styles.inputStyle}
                keyboardType="numeric"
                dense
                value={numberOfD12}
                onChangeText={(text) => {
                  setNumberOfD12(text);
                }}
              />
              <Text>Number of D20 Dice:</Text>
              <TextInput
                style={styles.inputStyle}
                keyboardType="numeric"
                dense
                value={numberOfD20}
                onChangeText={(text) => {
                  setNumberOfD20(text);
                }}
              />
              <Text>Number of D100 Dice:</Text>
              <TextInput
                style={styles.inputStyle}
                keyboardType="numeric"
                dense
                value={numberOfD100}
                onChangeText={(text) => {
                  setNumberOfD100(text);
                }}
              />
              <Button
                mode="contained"
                onPress={async () => {
                  await editProfiles();
                  setSuccessEditingProfile(true);
                  setAreEditing(false);
                  setProfileToEdit("");
                  getProfiles();
                }}
              >
                Finish Edit
              </Button>
              <Button
                style={{ margin: 10 }}
                mode="contained"
                onPress={() => {
                  setAreEditing(false);
                  setProfileToEdit("");
                  getProfiles();
                }}
              >
                Close
              </Button>
            </View>
          </View>
        </ScrollView>
      </Modal>
      <Modal
        visible={successEditingProfile}
        animationType="slide"
        transparent={true}
        onDismiss={() => {
          setSuccessEditingProfile(false);
          getProfiles();
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text>Profile Edited!</Text>
            <View style={{ margin: 10 }}>
              <Button
                mode="contained"
                title="Close"
                onPress={() => {
                  setSuccessEditingProfile(false);
                  getProfiles();
                }}
              >
                Close
              </Button>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        style={{ minWidth: 300 }}
        visible={areAdding}
        animationType="slide"
        transparent={true}
        onDismiss={() => {
          setAreAdding(false);
        }}
      >
        <ScrollView>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Text>Profile Name:</Text>
              <TextInput
                style={styles.inputStyle}
                dense
                value={profileName}
                onChangeText={(text) => {
                  setProfileName(text);
                }}
              />
              <Text>Number of D4 Dice:</Text>
              <TextInput
                style={styles.inputStyle}
                keyboardType="numeric"
                dense
                value={numberOfD4}
                onChangeText={(text) => {
                  setNumberOfD4(text);
                }}
              />
              <Text>Number of D6 Dice:</Text>
              <TextInput
                style={styles.inputStyle}
                keyboardType="numeric"
                dense
                value={numberOfD6}
                onChangeText={(text) => {
                  setNumberOfD6(text);
                }}
              />
              <Text>Number of D8 Dice:</Text>
              <TextInput
                style={styles.inputStyle}
                keyboardType="numeric"
                dense
                value={numberOfD8}
                onChangeText={(text) => {
                  setNumberOfD8(text);
                }}
              />
              <Text>Number of D10 Dice:</Text>
              <TextInput
                style={styles.inputStyle}
                keyboardType="numeric"
                dense
                value={numberOfD10}
                onChangeText={(text) => {
                  setNumberOfD10(text);
                }}
              />
              <Text>Number of D12 Dice:</Text>
              <TextInput
                style={styles.inputStyle}
                keyboardType="numeric"
                dense
                value={numberOfD12}
                onChangeText={(text) => {
                  setNumberOfD12(text);
                }}
              />
              <Text>Number of D20 Dice:</Text>
              <TextInput
                style={styles.inputStyle}
                keyboardType="numeric"
                dense
                value={numberOfD20}
                onChangeText={(text) => {
                  setNumberOfD20(text);
                }}
              />
              <Text>Number of D100 Dice:</Text>
              <TextInput
                style={styles.inputStyle}
                keyboardType="numeric"
                dense
                value={numberOfD100}
                onChangeText={(text) => {
                  setNumberOfD100(text);
                }}
              />
              <Button
                mode="contained"
                onPress={async () => {
                  await addProfiles();
                  setSuccessAddingProfile(true);
                  setAreAdding(false);
                  getProfiles();
                }}
              >
                Finish Add
              </Button>
              <Button
                style={{ margin: 10 }}
                mode="contained"
                onPress={() => {
                  setAreAdding(false);
                  getProfiles();
                }}
              >
                Close
              </Button>
            </View>
          </View>
          <StatusBar style="auto" />
        </ScrollView>
      </Modal>
      <Modal
        visible={successAddingProfile}
        animationType="slide"
        transparent={true}
        onDismiss={() => {
          setSuccessAddingProfile(false);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text>Profile Added!</Text>
            <View style={{ margin: 10 }}>
              <Button
                mode="contained"
                title="Close"
                onPress={() => {
                  setSuccessAddingProfile(false);
                }}
              >
                <Text>Close</Text>
              </Button>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#27394A",
  },
  button: {
    margin: 20,
    padding: 20,
  },
  text: {
    textAlign: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "stretch",
    marginTop: 15,
  },
  middleButton: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  navButton: {
    margin: 20,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 2,
  },
  modalView: {
    margin: 10,
    backgroundColor: "#262120",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalRowView: {
    flex: 1,
    flexDirection: "row",
    margin: 20,
    backgroundColor: "#262120",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalText: {
    marginBottom: 15,
    fontSize: 20,
    color: "white",
    textAlign: "center",
  },
  inputStyle: {
    minWidth: 200,
  },
});
