import React from "react";
import { StatusBar } from "expo-status-bar";
import { View, Platform, StyleSheet, ScrollView, Modal } from "react-native";
import { Text, Button, Card, Portal, IconButton } from "react-native-paper";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useState, useEffect } from "react";
import * as Haptics from "expo-haptics";
import { Audio } from "expo-av";

export default function Dashboard({ navigation }) {
  const [profiles, setProfiles] = useState([]);
  const [sound, setSound] = useState();
  const [updateToggle, setUpdateToggle] = useState(false);
  const [profileDetails, setPrfileDetails] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState({
    name: "",
    dice: [],
    total: 0,
  });
  const [areIcons, setAreIcons] = useState(false);
  const [isSoundPlaying, setIsSoundPlaying] = useState(false);

  function wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }

  const rollProfile = (profileName) => {
    const profile = profiles.filter((profile) => profile.name === profileName);
    const dice = profile[0].dice;
    var total = 0;
    var counter = 0;
    dice.map((die) => {
      if (die > 0) {
        //logic for D4
        if (counter === 0) {
          counter++;
          for (let i = 0; i < die; i++) {
            total += Math.floor(Math.random() * 4) + 1;
          }
        }
        //logic for D6
        else if (counter === 1) {
          counter++;
          for (let i = 0; i < die; i++) {
            total += Math.floor(Math.random() * 6) + 1;
          }
        }
        //logic for D8
        else if (counter === 2) {
          counter++;
          for (let i = 0; i < die; i++) {
            total += Math.floor(Math.random() * 8) + 1;
          }
        }
        //logic for D10
        else if (counter === 3) {
          counter++;
          for (let i = 0; i < die; i++) {
            total += Math.floor(Math.random() * 10) + 1;
          }
        }
        //logic for D12
        else if (counter === 4) {
          counter++;
          for (let i = 0; i < die; i++) {
            total += Math.floor(Math.random() * 12) + 1;
          }
        }
        //logic for D20
        else if (counter === 5) {
          counter++;
          for (let i = 0; i < die; i++) {
            total += Math.floor(Math.random() * 20) + 1;
          }
        }
        //logic for D100
        else if (counter === 6) {
          counter++;
          for (let i = 0; i < die; i++) {
            total += Math.floor(Math.random() * 100) + 1;
          }
        }
      }
      return total;
    });
    return total;
  };

  async function playDiceSound() {
    try {
      const { sound } = await Audio.Sound.createAsync(
        require("../../assets/sounds/rollingdice.mp4")
      );
      //setSound(sound);
      if (isSoundPlaying) {
        return;
      } else {
        await sound.playAsync();
      }
    } catch {
      (e) => console.log(e);
    }
  }

  async function playShortDiceSound() {
    try {
      const { sound } = await Audio.Sound.createAsync(
        require("../../assets/sounds/shortRollDice.mp4")
      );
      //setSound(sound);
      if (isSoundPlaying) {
        return;
      } else {
        await sound.playAsync();
      }
    } catch {
      (e) => console.log(e);
    }
  }

  const shakePhone = async () => {
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
    wait(100);
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
    wait(100);
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Warning);
    wait(100);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    wait(100);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
    wait(100);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
    wait(100);
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
    wait(100);
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
    wait(100);
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Warning);
    wait(100);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    wait(100);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
    wait(100);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
    wait(100);
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
    wait(100);
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Error);
    wait(100);
    Haptics.notificationAsync(Haptics.NotificationFeedbackType.Warning);
    wait(100);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    wait(100);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
    wait(100);
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
    wait(200);
    return;
  };

  const shaker = async () => {
    if (Platform.OS !== "web") {
      if (isSoundPlaying) {
        return;
      } else {
        try {
          await playDiceSound();
          await shakePhone();
        } catch {
          (e) => console.log(e);
        }
        return;
      }
    } else if (Platform.OS === "web") {
      if (isSoundPlaying) {
        return;
      } else {
        try {
          await playDiceSound();
        } catch {
          (e) => console.log(e);
        }

        wait(2000);
        return;
      }
    }
  };

  const getProfiles = async () => {
    const tempProfiles = JSON.parse(await AsyncStorage.getItem("Profiles"));
    if (tempProfiles !== null) {
      //adding totals field to each profile
      for (let i = 0; i < tempProfiles.length; i++) {
        tempProfiles[i].total = 0;
      }
      setProfiles(tempProfiles);
    }
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener("focus", () => {
      getProfiles();
    });
    return unsubscribe;
  }, [navigation]);

  return (
    <View style={styles.container}>
      <ScrollView
        style={{
          width: "100%",
          marginTop: 20,
        }}
        contentContainerStyle={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {profiles.map((profile) => {
          return (
            <Card
              key={profile.name}
              style={{
                margin: 20,
                maxWidth: 800,
                minWidth: 300,
                maxHeight: 200,
              }}
            >
              <Card.Title title={profile.name} />
              <Card.Content>
                <Text>Roll Total: {profile.total}</Text>

                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "end",
                  }}
                >
                  {areIcons ? (
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      <Button
                        mode="contained"
                        title="Roll Profile"
                        onPress={async () => {
                          shaker();
                          const temptotal = rollProfile(profile.name);
                          const tempProfiles = profiles;
                          console.log(profile);
                          profile.total = temptotal;
                          tempProfiles[profiles.indexOf(profile)] = profile;
                          setProfiles(tempProfiles);
                          setUpdateToggle(!updateToggle);
                        }}
                      >
                        Roll Profile
                      </Button>
                      <Button
                        mode="contained"
                        title="Fast Roll"
                        onPress={async () => {
                          playShortDiceSound();
                          const temptotal = rollProfile(profile.name);
                          const tempProfiles = profiles;
                          console.log(profile);
                          profile.total = temptotal;
                          tempProfiles[profiles.indexOf(profile)] = profile;
                          setProfiles(tempProfiles);
                          setUpdateToggle(!updateToggle);
                        }}
                      >
                        Fast Roll
                      </Button>
                      <Button
                        mode="contained"
                        title="Clear Total"
                        onPress={() => {
                          const tempProfiles = profiles;
                          profile.total = 0;
                          tempProfiles[profiles.indexOf(profile)] = profile;
                          setProfiles(tempProfiles);
                          setUpdateToggle(!updateToggle);
                        }}
                      >
                        Clear Total
                      </Button>
                      <Button
                        mode="contained"
                        title="Details"
                        onPress={() => {
                          setPrfileDetails(true);
                          setSelectedProfile(profile);
                        }}
                      >
                        Details
                      </Button>
                    </View>
                  ) : (
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      <IconButton
                        mode="contained"
                        icon="dice-multiple-outline"
                        size={24}
                        onPress={async () => {
                          try {
                            await shaker();
                          } catch {}
                          setIsSoundPlaying(true);
                          const temptotal = rollProfile(profile.name);
                          const tempProfiles = profiles;
                          console.log(profile);
                          profile.total = temptotal;
                          tempProfiles[profiles.indexOf(profile)] = profile;
                          setProfiles(tempProfiles);
                          setIsSoundPlaying(false);
                          setUpdateToggle(!updateToggle);
                        }}
                      />
                      <IconButton
                        mode="contained"
                        icon="lightning-bolt"
                        size={24}
                        onPress={async () => {
                          try {
                            playShortDiceSound();
                          } catch {}
                          const temptotal = rollProfile(profile.name);
                          const tempProfiles = profiles;
                          console.log(profile);
                          profile.total = temptotal;
                          tempProfiles[profiles.indexOf(profile)] = profile;
                          setProfiles(tempProfiles);
                          setUpdateToggle(!updateToggle);
                        }}
                      />
                      <IconButton
                        mode="contained"
                        icon="window-close"
                        size={24}
                        onPress={() => {
                          const tempProfiles = profiles;
                          profile.total = 0;
                          tempProfiles[profiles.indexOf(profile)] = profile;
                          setProfiles(tempProfiles);
                          setUpdateToggle(!updateToggle);
                        }}
                      />
                      <IconButton
                        mode="contained"
                        icon="information-outline"
                        size={24}
                        onPress={() => {
                          setPrfileDetails(true);
                          setSelectedProfile(profile);
                        }}
                      />
                    </View>
                  )}
                </View>
              </Card.Content>
            </Card>
          );
        })}
        <View style={styles.navButton}>
          <Button mode="contained" onPress={() => setAreIcons(!areIcons)}>
            Switch Button Mode
          </Button>
        </View>
        <View style={styles.navButton}>
          <Button
            mode="contained"
            onPress={() => navigation.navigate("Quick Roll")}
          >
            Quick Roll
          </Button>
        </View>

        <View style={styles.navButton}>
          <Button
            mode="contained"
            onPress={() => navigation.navigate("Profile Manager")}
          >
            Go to Profile Manager
          </Button>
        </View>
        <StatusBar style="auto" />
      </ScrollView>
      <Portal>
        <Modal
          visible={profileDetails}
          animationType="slide"
          transparent={true}
          onDismiss={() => {
            setPrfileDetails(false);
            setSelectedProfile({ name: "", dice: [], total: 0 });
          }}
        >
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <Text>Profile Details:</Text>
              <Text>{selectedProfile.name}</Text>
              <Text>Number of D4: {selectedProfile.dice[0]}</Text>
              <Text>Number of D6: {selectedProfile.dice[1]}</Text>
              <Text>Number of D8: {selectedProfile.dice[2]}</Text>
              <Text>Number of D10: {selectedProfile.dice[3]}</Text>
              <Text>Number of D12: {selectedProfile.dice[4]}</Text>
              <Text>Number of D20: {selectedProfile.dice[5]}</Text>
              <Text>Number of D100: {selectedProfile.dice[6]}</Text>
              <Button
                mode="contained"
                onPress={() => {
                  setPrfileDetails(false);
                  setSelectedProfile({ name: "", dice: [], total: 0 });
                }}
              >
                Close
              </Button>
            </View>
          </View>
        </Modal>
      </Portal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#27394A",
  },
  button: {
    margin: 20,
    padding: 20,
  },
  text: {
    textAlign: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "stretch",
    marginTop: 15,
  },
  middleButton: {
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  navButton: {
    margin: 20,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "#262120",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalText: {
    marginBottom: 15,
    fontSize: 20,
    color: "white",
    textAlign: "center",
  },
});
